export const MAX_FISH_DEEP_SEA = 3000;
export const MAX_FISH_COAST = 1500;
export const START_VALUE_INITIAL_FISH_DEEP_SEA = 2500;
export const START_VALUE_INITIAL_FISH_COAST = 1200;
export const OPERATING_COST_DEEP_SEA = 250;
export const OPERATING_COST_COAST = 150;
export const OPERATING_COST_HARBOR = 50;

export const NEW_SHIP_PRICE = 300;
export const INITIAL_SHIP_SALVAGE_VALUE = 250;
export const FISH_SALES_PRICE = 20;

export const THRESHOLD_DENSITY_FOR_POPULATION_WARNINGS = 0.4;

export const MAX_TEAM_NAME_LENGTH = 30;
