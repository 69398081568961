import React from "react";
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Message, Form, Card, Button, Icon } from 'semantic-ui-react';

import SelectNumberOfPlayers from './SelectNumberOfPlayers';
import TeamForm from './TeamForm';
import { PRIMARY_COLOR } from '../stylingConstants';
import { setInitialConditions, quitCurrentGame } from '../actions';
import {
  initialNumberOfShipsFromNumberOfTeams,
  initialBankBalanceFromNumberOfTeams
} from '../lib/setup';

import {
  MAX_FISH_DEEP_SEA,
  MAX_FISH_COAST,
  START_VALUE_INITIAL_FISH_DEEP_SEA,
  START_VALUE_INITIAL_FISH_COAST,
  OPERATING_COST_DEEP_SEA,
  OPERATING_COST_COAST,
  OPERATING_COST_HARBOR,
  NEW_SHIP_PRICE,
  INITIAL_SHIP_SALVAGE_VALUE,
  FISH_SALES_PRICE,
} from '../constants'


class SetupForm extends React.Component {
  constructor(props) {
    super(props);
    const numberOfTeams = props.joinedTeams.length || null;
    const numberOfShips = initialNumberOfShipsFromNumberOfTeams(numberOfTeams);
    const bankBalance = initialBankBalanceFromNumberOfTeams(numberOfTeams);
    const teams = props.joinedTeams.map(team => {return {name: team.name, id: team.id, numberOfShips, bankBalance}});
    this.state = {
      numberOfTeams,
      teams,
      name: `Session from ${new Date().toISOString().substring(0,10)}`,
      initialFishDeepSea: START_VALUE_INITIAL_FISH_DEEP_SEA,
      initialFishCoast: START_VALUE_INITIAL_FISH_COAST,
      formDataProcessed: false,
      redirectToHome: false,
    }

    this.changedName = this.changedName.bind(this);
    this.changedInitialFishDeepSea = this.changedInitialFishDeepSea.bind(this);
    this.changedInitialFishCoast = this.changedInitialFishCoast.bind(this);
  }

  changedName(event) {
    this.setState({name: event.target.value})
    this.validateAll({name: event.target.value});
  }

  changedInitialFishDeepSea(event) {
    this.setState({initialFishDeepSea: event.target.value});
    this.validateAll({initialFishDeepSea: event.target.value});
  }

  changedInitialFishCoast(event) {
    this.setState({initialFishCoast: event.target.value});
    this.validateAll({initialFishCoast: event.target.value});
  }

  validateAll(overridingState = {}) {
    const stateToValidate = {...this.state, ...overridingState}

    const teamsValid = this.validateTeams(stateToValidate.teams);
    const nameValid = this.validateName(stateToValidate.name);
    const initialFishDeepSeaValid = this.validateInitialFishDeepSea(stateToValidate.initialFishDeepSea);
    const initialFishCoastValid = this.validateInitialFishCoast(stateToValidate.initialFishCoast);
    this.setState({ formValid: teamsValid && nameValid && initialFishDeepSeaValid && initialFishCoastValid });
  }

  validateTeams(teams) {
    for(const team of teams) {
      if(!team.formValid) {
        return false;
      }
    }
    return true;
  }

  validateName(name) {
    const nameLength = name.length;
    if( nameLength < 1 ) {
      this.setState({nameError: "You must enter a name"});
      return false;
    }
    const MAX_NAME_LENGTH = 30;
    if( nameLength > MAX_NAME_LENGTH ) {
      this.setState({nameError: `Maximum length of name is ${MAX_NAME_LENGTH} characters`});
      return false;
    }
    this.setState({nameError: ""});
    return  true;
  }

  validateInitialFishDeepSea(initialFishDeepSea) {
    const initialFishDeepSeaValue = parseInt(initialFishDeepSea);
    if(isNaN(initialFishDeepSeaValue) || initialFishDeepSeaValue < 1) {
      this.setState({initialFishDeepSeaError: "Initial fish in deep sea must be a number greater than zero."});
      return false;
    }
    if(initialFishDeepSeaValue > MAX_FISH_DEEP_SEA) {
      this.setState({initialFishDeepSeaError: "Initial fish in deep sea must not be higher than the maximum amount of fish in the deep sea."});
      return false;
    }
    this.setState({initialFishDeepSeaError: ""});
    return true;
  }

  validateInitialFishCoast(initialFishCoast) {
    const initialFishCoastValue = parseInt(initialFishCoast);
    if(isNaN(initialFishCoastValue) || initialFishCoastValue < 1) {
      this.setState({initialFishCoastError: "Initial fish in the coast area must be a number greater than zero"});
      return false;
    }
    if( initialFishCoastValue > MAX_FISH_COAST) {
      this.setState({initialFishCoastError: "Initial fish in the coast area must not be higher than the maximum amount of fish in the coast area."});
      return false;
    }
    this.setState({initialFishCoastError: ""});
    return true;
  }

  numberOfTeamsSelected(numberOfTeams) {
    numberOfTeams = parseInt(numberOfTeams);
    this.setState({ numberOfTeams });
    let initialTeams = [];
    const numberOfShips = initialNumberOfShipsFromNumberOfTeams(numberOfTeams);
    const bankBalance = initialBankBalanceFromNumberOfTeams(numberOfTeams);
    for(let teamNumber = 1; teamNumber <= numberOfTeams; teamNumber++ ) {
      initialTeams.push({name: `Team ${teamNumber}`, numberOfShips, bankBalance});
    }
    this.setState({ initialTeams , teams: initialTeams});
  }

  onTeamWithIndexChanged(index) {
    return (team) => {
      let teams = this.state.teams;
      teams[index] = {...teams[index], ...team};
      this.validateAll({teams});
      this.setState({ teams })
    };
  }

  onFormCompletedPressed(event) {
    event.preventDefault();

    const initialGameData = {
        teams: this.state.teams.map( function(team, index) {
          return {
            id: team.id,
            name: team.name,
            number: index + 1,
            numberOfShips: parseInt(team.numberOfShips),
            bankBalance: parseInt(team.bankBalance)
          };
        }),
        gameId: this.props.gameId,
        name: this.state.name,
        fishDeepSea: parseInt(this.state.initialFishDeepSea),
        fishCoast: parseInt(this.state.initialFishCoast),
        year: 0,
        maximumFishDeepSea: MAX_FISH_DEEP_SEA,
        maximumFishCoast: MAX_FISH_COAST,
        operatingCostCoast: OPERATING_COST_COAST,
        operatingCostDeepSea: OPERATING_COST_DEEP_SEA,
        operatingCostHarbor: OPERATING_COST_HARBOR,
        newShipPrice: NEW_SHIP_PRICE,
        shipSalvageValue: INITIAL_SHIP_SALVAGE_VALUE,
        fishSalesPrice: FISH_SALES_PRICE
    };
    this.props.setInitialConditions(initialGameData);
    this.setState({formDataProcessed: true});
  }

  backToSelectNumberOfTeams(event) {
    event.preventDefault();
    this.setState({numberOfTeams: null});
  }

  backToStartPage(event) {
    event.preventDefault();
    this.props.quitCurrentGame();
    this.setState({redirectToHome: true});
  }

  renderTeams() {
    return( this.state.teams.map( (team, index) => {
        return <TeamForm team={team} teamNumber={index + 1} onTeamChanged={this.onTeamWithIndexChanged(index)} key={index}/>
      })
    );
  }

  render() {
    if (this.props.redirectToHome || this.state.redirectToHome) {
      return (
        <Redirect to='/' />
      );
    }
    if (this.state.numberOfTeams === null ) {
      return (
        <SelectNumberOfPlayers numberOfTeamsSelected={this.numberOfTeamsSelected.bind(this)}/>
      );
    }
    if (this.state.formDataProcessed === true) {
      return (
        <Redirect to='show' />
      );
    }
    return (
      <div>
        {this.props.joinedTeams.length === 0 && (
          <Button onClick={this.backToSelectNumberOfTeams.bind(this)}>
            <Icon name="angle left" />Back to select number of teams
          </Button>
        )}
        {this.props.joinedTeams.length > 0 && (
          <Button onClick={this.backToStartPage.bind(this)}>
            <Icon name="angle left" />Back to start page
          </Button>
        )}
      <h2>Set up initial conditions</h2>
      <Message info>
      <Message.Header>These are the recommended initial values</Message.Header>
      <p>
      The values are possible to change, however, we recommend that you use the
      default values unless you are an experienced user.
      </p>
      </Message>

      <Form onSubmit={this.handleSubmit} error={!this.state.formValid} >
        <Form.Input
          label="Session name"
          type="text"
          value={this.state.name}
          onChange={this.changedName}
          {...(this.state.nameError ?
            {error: {content: this.state.nameError }} : {})}
          />

        <h3>Teams</h3>
        <Card.Group>
        {this.renderTeams()}
        </Card.Group>

        <h3>Initial fish</h3>
        <Form.Input
          type="number"
          value={this.state.initialFishDeepSea}
          label={`Initial fish in Deep Sea (Maximum: ${MAX_FISH_DEEP_SEA}) `}
          onChange={this.changedInitialFishDeepSea}
          {...(this.state.initialFishDeepSeaError ?
            {error: {content: this.state.initialFishDeepSeaError }} : {})}
        />

        <Form.Input
          type="number"
          value={this.state.initialFishCoast}
          label={`Initial fish at Coast (Maximum: ${MAX_FISH_COAST}) `}
          onChange={this.changedInitialFishCoast}
          {...(this.state.initialFishCoastError ?
            {error: {content: this.state.initialFishCoastError }} : {})}
        />

        <h3>Constants</h3>
        <table className="ui definition table unstackable">
        <thead>
          <tr><th>&nbsp;</th><th>Deep sea</th><th>Coast</th><th>Harbor</th></tr>
        </thead>
        <tbody>
          <tr>
            <td>Operating cost</td>
            <td>{OPERATING_COST_DEEP_SEA}</td>
            <td>{OPERATING_COST_COAST}</td>
            <td>{OPERATING_COST_HARBOR}</td>
          </tr>
        </tbody>
        </table>

        <div style={{ fontWeight: 'bold'}}>
        <p>New ship price: {NEW_SHIP_PRICE}</p>
        <p>Ship salvage value: {INITIAL_SHIP_SALVAGE_VALUE}</p>
        <p>Fish sales price: {FISH_SALES_PRICE}</p>
        </div>
        <Form.Button
          fluid
          className="spaced"
          type="submit"
          size='big'
          onClick={this.onFormCompletedPressed.bind(this)}
          disabled={!this.state.formValid}
          color={PRIMARY_COLOR}
        >
          Continue
        </Form.Button>

      </Form>
      </div>
      );
    }

}

const mapStateToProps = (state) => {
  return {
    redirectToHome: !state.licenseKey,
    joinedTeams: state.joinedTeams,
    gameId: state.onlineState.gameId,
  };
}

export default connect(mapStateToProps, {
  setInitialConditions,
  quitCurrentGame,
})(SetupForm);
