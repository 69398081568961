import React from 'react';
import { Message } from 'semantic-ui-react';


import { THRESHOLD_DENSITY_FOR_POPULATION_WARNINGS } from '../constants';

class Notices extends React.Component {

  renderDeepSeaPopulationWarning() {
    const gameStates = this.props.gameStates;
    if(gameStates.length < 2 ) {
      return null;
    }
    const currentCoastPopulationBelowThreshold =
      gameStates[gameStates.length - 1].operatorReportData.fishDensityDeepSea <
      THRESHOLD_DENSITY_FOR_POPULATION_WARNINGS;
    const previousCoastPopulationBelowThreshold =
      gameStates[gameStates.length - 2].operatorReportData.fishDensityDeepSea <
      THRESHOLD_DENSITY_FOR_POPULATION_WARNINGS;
    if(currentCoastPopulationBelowThreshold && !previousCoastPopulationBelowThreshold) {
      return (
        <Message warning>
          <Message.Header>
            Deep sea population density is below {THRESHOLD_DENSITY_FOR_POPULATION_WARNINGS}
          </Message.Header>
          <p>
          This means that we have a system collapse in the deep sea population.
          Tell the story about the village beeing a success story (people moving
          here, job opportunities etc). BUT there are some pessimistic professors
          saying that the fishing in the village is unsustainable.
          </p>
          <p>
            If you are playing an online game this message is only visible to you as a host.
          </p>
        </Message>
      );
    }
    return null;
  }

  renderCoastPopulationWarning() {
    const gameStates = this.props.gameStates;
    if(gameStates.length < 2 ) {
      return null;
    }
    const currentCoastPopulationBelowThreshold =
      gameStates[gameStates.length - 1].operatorReportData.fishDensityCoast <
      THRESHOLD_DENSITY_FOR_POPULATION_WARNINGS;
    const previousCoastPopulationBelowThreshold =
      gameStates[gameStates.length - 2].operatorReportData.fishDensityCoast <
      THRESHOLD_DENSITY_FOR_POPULATION_WARNINGS;
    if(currentCoastPopulationBelowThreshold && !previousCoastPopulationBelowThreshold) {
      return (
        <Message warning>
          <Message.Header>
            Coast population density is below {THRESHOLD_DENSITY_FOR_POPULATION_WARNINGS}
          </Message.Header>
          <p>
          This means that we have a system collapse in the coast population.
          You can give the students a second chance to save the population.
          Tell them the second chance story and give them the possibility to change
          their ship allocation (D9-D11). In case they all change their orders, move back one year.
          Click the Game menu and choose the "Move back one year" option, then reenter all decisions.
          </p>
          <p>
            If you are playing an online game this message is only visible to you as a host.
          </p>
        </Message>
      );
    }
    return null;
  }

  render() {
    return (
      <>
        {this.renderDeepSeaPopulationWarning()}
        {this.renderCoastPopulationWarning()}
      </>
    );
  }

}

export default Notices;
