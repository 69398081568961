import React from "react";
import { Form, Button, Select, Icon } from 'semantic-ui-react';
import { PRIMARY_COLOR } from '../stylingConstants';
import { Link } from 'react-router-dom';


const INITIAL_NUMBER_OF_TEAMS = 4;

export default class SelectNumberOfPlayers extends React.Component {
  constructor(props) {
    super(props);

    this.state = { numberOfTeams: INITIAL_NUMBER_OF_TEAMS }

    this.numberOfTeamsChanged = this.numberOfTeamsChanged.bind(this);
    this.continueButtonPressed = this.continueButtonPressed.bind(this);
  }

  numberOfTeamsChanged(event, {value}) {
    this.setState({numberOfTeams: value});
  }

  continueButtonPressed(event) {
    event.preventDefault();
    this.props.numberOfTeamsSelected(this.state.numberOfTeams)
  }

  render() {

    const numberOfTeamsOptions = [
      { key: '1', value: 1, text: '1 team' },
      { key: '2', value: 2, text: '2 teams' },
      { key: '3', value: 3, text: '3 teams' },
      { key: '4', value: 4, text: '4 teams' },
      { key: '5', value: 5, text: '5 teams' },
      { key: '6', value: 6, text: '6 teams' },
    ];

    return(
      <div>
        <Link to="/">
          <Button><Icon name="angle left" />Back to start page</Button>
        </Link>
        <h2>Select number of teams</h2>
        <p>
          Make sure that each team consists of at least two players,
          in order to encourage discussions within the teams.
          Usually it is better to have more teams than fewer.
        </p>
        <Form>
          <Form.Field>
            <Select
              onChange={this.numberOfTeamsChanged}
              value={this.state.numberOfTeams}
              options={numberOfTeamsOptions} />
          </Form.Field>
          <Button
            color={PRIMARY_COLOR}
            onClick={this.continueButtonPressed}
          >
            Continue
          </Button>
        </Form>
      </div>
    );
  }
}
