import React from 'react';
import {renderDollarAmount, teamDisplayName} from "../lib/util";
import {Image} from "semantic-ui-react";
import signature from "../images/signature_en.png";

export default function TeamReport({team, year, fishSalesPrice}) {

  const emailTextForYear = (year) => {
    switch(year) {
      case 1:
        return "I heard that the company has chosen a new board of directors."
          + " I look forward to working together with you! I have compiled key"
          + " figures for the year before you took over responsibility of the"
          + " company (I call it year 1)."
          + " Please see the table to the right.";
      //return "Hörde att företaget fått ny styrelse - ser fram emot att "
      //  + " samarbeta med er! Jag har sammanställt nyckeltal för året innan"
      //  + " ni tillträdde era poster (jag kallar året för 'år 1'). Vänligen"
      //  + " se tabellen till höger."
      case 2:
        return "Thank you for a year of good collaboration. I have compiled "
          + " this years key figures in the table to the right.";
      //return "Tack för ett år av gott samarbete. Jag har sammanställt"
      //  + " årets nyckeltal i tabellen till höger.";
      case 3:
        return "Thank you for yet another year of good cooperation. The key "
          + " figures for this year are in the table to the right.";
      //return "Tack för ännu ett år av gott samarbete. Jag har sammanställt"
      //  + " årets nyckeltal i tabellen till höger.";
      case 4:
      case 5:
        return "Thank you for this year. I have collected this years key"
          + " figures in the table on the right."
      //return "Tack för detta år. Jag har sammanställt årets nyckeltal i"
      //  + " tabellen till höger";
      default:
        return "Please review the key figures for this year.";
      //return "Vänligen se över sammanställningen av årets nyckeltal";
    }
  };

  const {deepSeaCatch, coastCatch, totalFishSales, interest} = team.teamReportData;

  return (
    <div style={{pageBreakInside: 'avoid'}}>
      <div style={{display: 'flex'}}>
        <div style={{width: '65%'}}>
          <p><strong>From: </strong>auditor@fishauditors.com <br/>
            <strong>To: </strong>{teamDisplayName(team)}<br/>
            <strong>Subject: </strong>Annual Report for {team.name} </p>
          <p>{emailTextForYear(year)}</p>
          <p>Best Regards</p>
          <Image src={signature} style={{width: '100px'}} alt="Aud Itor"></Image>
        </div>
        <div style={{marginLeft: '20px'}}>
          <table>
            <thead>
            <tr><th colSpan="3" >Key figures for year {year}</th></tr>
            </thead>
            <tbody>
            <tr><td>R:1</td><td>Deep Sea Catch</td><td>{deepSeaCatch} <span role="img" aria-label="fish">🐟</span></td></tr>
            <tr><td>R:2</td><td>Coast Catch</td><td>{coastCatch} <span role="img" aria-label="fish">🐟</span></td></tr>
            <tr><td>R:3</td><td>Price of Fish</td><td>{renderDollarAmount(fishSalesPrice)}</td></tr>
            <tr><td>R:4</td><td>Total Fish Sales</td><td>{renderDollarAmount(totalFishSales)}</td></tr>
            <tr><td>R:5</td><td>Interest</td><td>{renderDollarAmount(interest)}</td></tr>
            <tr><td>R:6</td><td>Bank Balance</td><td>{renderDollarAmount(team.bankBalance)}</td></tr>
            <tr><td>R:7</td><td>Ship Fleet</td><td>{team.numberOfShips} <span role="img" aria-label="ship">🚢</span></td></tr>
            </tbody>
          </table>
        </div>
      </div>
      <hr />
    </div>
  );
}