import React from "react";

import { Form, Card } from 'semantic-ui-react';

import { MAX_TEAM_NAME_LENGTH } from '../constants';

export default class TeamForm extends React.Component {
  constructor(props) {
    super(props);
    if(props.team) {
      var team = props.team;
      this.state = {
        name: team.name,
        numberOfShips: team.numberOfShips,
        bankBalance: team.bankBalance
      };
    } else {
      this.state = {
        name: '',
        numberOfShips: 0,
        bankBalance: 0
      };
    }

    this.changedName = this.changedName.bind(this);
    this.changedBankBalance = this.changedBankBalance.bind(this);
    this.changedNumberOfShips = this.changedNumberOfShips.bind(this);
  }

  componentDidMount() {
    this.validateAll();
  }

  validateAll(overridingState = {}) {
    const stateToValidate = {...this.state, ...overridingState}

    const bankBalanceValid = this.validateBankBalance(stateToValidate.bankBalance);
    const numberOfShipsValid = this.validateNumberOfShips(stateToValidate.numberOfShips);
    const nameValid =  this.validateName(stateToValidate.name);
    const formValid = bankBalanceValid
      && numberOfShipsValid
      && nameValid;
    this.setState({ formValid });
    this.props.onTeamChanged({...stateToValidate, formValid });
  }

  validateBankBalance(balance) {
    const balanceValue = parseInt(balance);
    if(isNaN(balanceValue) || balanceValue < 1) {
      this.setState({ bankBalanceError: "Bank balance must be a number greater than zero" });
      return false;
    }
    this.setState({ bankBalanceError: "" });
    return true;
  }

  validateName(name) {
    const nameLength = name.length;
    if( nameLength < 1 ) {
      this.setState({nameError: "You must enter a name"});
      return false;
    }
    if( nameLength > MAX_TEAM_NAME_LENGTH ) {
      this.setState({nameError: `Maximum length of name is ${MAX_TEAM_NAME_LENGTH} characters`});
      return false;
    }
    this.setState({nameError: ""});
    return  true;
  }

  validateNumberOfShips(numberOfShips) {
    const numberOfShipsValue = parseInt(numberOfShips);
    if(isNaN(numberOfShipsValue) || numberOfShipsValue < 1) {
      this.setState({numberOfShipsError: "Number of ships must be a number greater than zero"});
      return false;
    }
    this.setState({numberOfShipsError: ""});
    return true;
  }

  changedName(event) {
    this.setState({name: event.target.value});
    this.validateAll({name: event.target.value});
  }

  changedBankBalance(event) {
    this.setState({bankBalance: event.target.value});
    this.validateAll({bankBalance: event.target.value});
  }

  changedNumberOfShips(event) {
    this.setState({numberOfShips: event.target.value});
    this.validateAll({numberOfShips: event.target.value});
  }

  render() {
    return (
      <Card>
        <Card.Content>
          <Card.Header>Team {this.props.teamNumber}</Card.Header>
        </Card.Content>
        <Card.Content>
        <Form.Input
          label='Name'
          value={this.state.name}
          onChange={this.changedName}
          {...(this.state.nameError ?
            {error: {content: this.state.nameError }} : {})}
        />
        <Form.Input
          label='Ships'
          type='number'
          value={this.state.numberOfShips}
          onChange={this.changedNumberOfShips}
          {...(this.state.numberOfShipsError ?
            {error: {content: this.state.numberOfShipsError }} : {})}
        />
        <Form.Input
          label='Bank balance'
          type='number'
          value={this.state.bankBalance}
          onChange={this.changedBankBalance}
          {...(this.state.bankBalanceError ?
            {error: {content: this.state.bankBalanceError }} : {})}
        />
        </Card.Content>
      </Card>
    );
  }
}
