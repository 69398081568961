import React from "react";
import { Redirect } from 'react-router-dom';
import {Button, Form} from 'semantic-ui-react';
import {PRIMARY_COLOR} from "../stylingConstants";
import {connect} from "react-redux";
import {joinedAsTeam, receivedFromTeamChannel} from "../actions";
import SocketHandler from "../lib/SocketHandler";

class JoinGameForm extends React.Component {

  state = {
    joinCode: "",
    teamName: "",
    joinCodeErrorMessage: "",
    teamNameErrorMessage: "",
    joinCodeCorrectLength: false,
  }

  onChangeJoinCode(event) {
    this.setState({
      joinCode: event.target.value,
      joinCodeErrorMessage: '',
      joinCodeCorrectLength: event.target.value.length === 6
    });
  }

  onChangeTeamName(event) {
    this.setState({
      teamName: event.target.value,
      teamNameErrorMessage: event.target.value.length === 0 ? 'Team name is mandatory' : '',
    })
  }

  onJoinClicked() {
    if (this.state.teamName.length == 0) {
      this.setState({
        teamNameErrorMessage: 'Team name is mandatory',
      })
      return;
    }
    fetch("/games/join", {
      "method": "POST",
      "headers": {
        "content-type": "application/json",
        "accept": "application/json"
      },
      "body": JSON.stringify({'code': this.state.joinCode, team_name: this.state.teamName})
    })
      .then(response => response.json())
      .then(team => {
        console.log(team)
        if( team === null) {
          this.setState({
              joinCodeErrorMessage: 'Could not find a game with that code, please double check it'
            }
          );
        } else {
          const outerThis = this;
          SocketHandler.subscribeToTeamChannel(team.id, {
            received(data) {
              console.log(data);
              outerThis.props.receivedFromTeamChannel(data);
            }});
          this.props.joinedAsTeam(team);
        }
      })
      .catch(err => {
        this.setState({
            joinCodeErrorMessage: 'Could not find a game with that code, please double check it.'
          }
        );
        console.log(err);
      });
  }


  render() {
    if( this.props.redirectToShow) {
      return <Redirect to='show' />;
    }
    if( this.props.joined ) {
      return (
        <>
          <h2>Successfully joined</h2>
          <p>Waiting for the host to start the game.</p>
        </>
      );
    }
    return (
      <>
        <h2>Join a game</h2>
        <p>
          To join a game, enter the code you received from the host.
        </p>
        <Form>
          <Form.Input
            required
            type="text"
            label="Game code"
            value={this.state.joinCode}
            onChange={this.onChangeJoinCode.bind(this)}
            {...(this.state.joinCodeErrorMessage ?
              {error: {content: this.state.joinCodeErrorMessage }} : {})}
          />
          <Form.Input
            required
            type="text"
            label="Team name"
            value={this.state.teamName}
            onChange={this.onChangeTeamName.bind(this)}
            {...(this.state.teamNameErrorMessage ?
              {error: {content: this.state.teamNameErrorMessage }} : {})}
          />
          <Button
            color={PRIMARY_COLOR}
            onClick={this.onJoinClicked.bind(this)}
            disabled={!(this.state.joinCodeCorrectLength && this.state.teamNameErrorMessage.length === 0 )}
          >
            Join Game
          </Button>
        </Form>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    joined: !!state.onlineState.teamId,
    redirectToShow: (state.onlineState.teamId && state.onlineState.phase !== 'WAITING_FOR_HOST')
      || state.onlineState.phase === 'PLAYING',
  }
};

export default connect(mapStateToProps, {
  joinedAsTeam, receivedFromTeamChannel
})(JoinGameForm);