import React from 'react';
import {
  XYPlot,
  LineSeries,
  LineMarkSeries,
  VerticalGridLines,
  HorizontalGridLines,
  XAxis,
  YAxis,
  ChartLabel,
  DiscreteColorLegend,
} from 'react-vis';
import { Tab } from 'semantic-ui-react';

import ScreenShotInstructionsPopup from './ScreenShotInstructionsPopup';
import '../../../../node_modules/react-vis/dist/style.css';

const BLUE_COLOR_CODE = "#4a7ebb";
const RED_COLOR_CODE = "#be4b48";
const GREEN_COLOR_CODE = "#98b855";

const GRAPH_MARGIN = {left: 40, right: 40, top: 30, bottom: 30};

class Graphs extends React.Component {

  constructor(props) {

    super(props);
    // Initial tab is Fish stock since it is most commonly used during the seminar
    this.state = {
      activeIndex: 3,
    }
  }

  showInitialTab = () => this.setState({ activeIndex: 0 });
  handleTabChange = (e, { activeIndex }) => this.setState({ activeIndex });


  shipIndexData() {
    return this.props.gameStates.map((gameState) => {
      return {x: gameState.year, y: gameState.operatorReportData.shipIndex}
    });
  }

  catchIndexData() {
    return this.props.gameStates.map((gameState) => {
      return {x: gameState.year, y: gameState.operatorReportData.catchIndex}
    });
  }

  fishIndexData() {
    return this.props.gameStates.map((gameState) => {
      return {x: gameState.year, y: gameState.operatorReportData.fishIndex}
    });
  }

  totalNumberOfShipsData() {
    return this.props.gameStates.map((gameState) => {
      return {x: gameState.year, y: gameState.operatorReportData.operatingFleetTotal}
    });
  }

  totalCatchDeepSeaData() {
    return this.props.gameStates.map((gameState) => {
      return {x: gameState.year, y: gameState.operatorReportData.totalCatchDeepSea}
    });
  }

  totalCatchCoastData() {
    return this.props.gameStates.map((gameState) => {
      return {x: gameState.year, y: gameState.operatorReportData.totalCatchCoast}
    });
  }

  totalFishDeepSeaData() {
    return this.props.gameStates.map((gameState) => {
      return {x: gameState.year, y: gameState.fishDeepSea}
    });
  }

  totalFishCoastData() {
    return this.props.gameStates.map((gameState) => {
      return {x: gameState.year, y: gameState.fishCoast}
    });
  }

  // Show only integer values on the year axis
  standardXAxis() {
    return (
      <XAxis
        // tickValues from 1 to the latest year, but at least to year 10
        tickValues={[...Array(Math.max(this.props.gameStates.length,10)+1).keys()].slice(1)}
        tickFormat={(val) => {return val;}}
      />
    );
  }

  standardXChartLabel() {
    return (
      <ChartLabel
      text="Year"
      className="alt-x-label"
      includeMargin={true}
      xPercent={0.95}
      yPercent={0.84}
      />
    );
  }

  standardYChartLabel(text) {
    return (
    <ChartLabel
    text={text}
    className="alt-y-label"
    includeMargin={false}
    xPercent={-0.06}
    yPercent={0.06}
    style={{
      //textAnchor: 'end'
    }}
    />
    );
  }

  lineSeriesToForcePlotToContain(x,y) {
    return <LineSeries data={[{x,y}]} />;
  }


  renderIndexGraph() {
    return (
      <>
      <h3>Ship, catch and fish indices</h3>
      <p>
      The indexes are normalized so they typically are between 0 and 10 throughout the game. So all three can easily be plotted on the same graph.<br/>
      Ship Index = 2 x Ship Fleet (Excluding ships ordered last year) / Initial Ship Fleet<br/>
      Catch Index = Total Catch / 300<br/>
      Fish Index = Total fish population / 450
      </p>
      <div style={{display: 'flex', alignItems: 'center'}}>
      <XYPlot height={300} width={600} margin={GRAPH_MARGIN}>
        <VerticalGridLines />
        <HorizontalGridLines />
        {this.standardXAxis()}
        <YAxis />
        {this.standardXChartLabel()}
        {this.standardYChartLabel('Index value')}
        <LineMarkSeries data={this.shipIndexData()} color={BLUE_COLOR_CODE} />
        <LineMarkSeries data={this.catchIndexData()} color={RED_COLOR_CODE} />
        <LineMarkSeries data={this.fishIndexData()} color={GREEN_COLOR_CODE} />
        {this.lineSeriesToForcePlotToContain(10,0)}
      </XYPlot>
      <DiscreteColorLegend items={[
        {title: 'Ship index', color: BLUE_COLOR_CODE},
        {title: 'Catch index', color: RED_COLOR_CODE},
        {title: 'Fish index', color: GREEN_COLOR_CODE},
        ]}
      />
      </div>
      <ScreenShotInstructionsPopup />
      </>
    );
  }

  renderTotalShipsGraph() {
    return (
      <>
      <h3>Total number of ships</h3>
      <XYPlot height={300} width={600} margin={GRAPH_MARGIN}>
        <VerticalGridLines />
        <HorizontalGridLines />
        {this.standardXAxis()}
        <YAxis/>
        {this.standardXChartLabel()}
        {this.standardYChartLabel('Number of Ships')}
        <LineMarkSeries data={this.totalNumberOfShipsData()} color={BLUE_COLOR_CODE} />
        {this.lineSeriesToForcePlotToContain(10,0)}
      </XYPlot>
      <ScreenShotInstructionsPopup />
      </>
    );
  }

  renderCatchGraph() {
    return (
      <>
      <h3>Catch</h3>
      <div style={{display: 'flex', alignItems: 'center'}}>
      <XYPlot height={300} width={600} margin={GRAPH_MARGIN}>
        <VerticalGridLines />
        <HorizontalGridLines />
        {this.standardXAxis()}
        <YAxis />
        {this.standardXChartLabel()}
        {this.standardYChartLabel('Number of fish')}
        <LineMarkSeries data={this.totalCatchCoastData()} color={BLUE_COLOR_CODE} />
        <LineMarkSeries data={this.totalCatchDeepSeaData()} color={RED_COLOR_CODE} />
        {this.lineSeriesToForcePlotToContain(10,0)}
      </XYPlot>
      <DiscreteColorLegend items={[
        {title: 'Coastal catch', color: BLUE_COLOR_CODE},
        {title: 'Deep sea catch', color: RED_COLOR_CODE},
        ]}
      />
      </div>
      <ScreenShotInstructionsPopup />
      </>
    );
  }

  renderFishStockGraph() {
    return (
      <>
      <h3>Fish stock</h3>
      <div style={{display: 'flex', alignItems: 'center'}}>
      <XYPlot width={600} height={300} margin={GRAPH_MARGIN}>
        <VerticalGridLines />
        <HorizontalGridLines />
        {this.standardXAxis()}
        <YAxis />
        {this.standardXChartLabel()}
        {this.standardYChartLabel('Number of fish')}
        <LineMarkSeries data={this.totalFishCoastData()} color={BLUE_COLOR_CODE} />
        <LineMarkSeries data={this.totalFishDeepSeaData()} color={RED_COLOR_CODE} />
        {this.lineSeriesToForcePlotToContain(10,0)}
      </XYPlot>
      <DiscreteColorLegend items={[
        {title: 'Fish population: Coastal', color: BLUE_COLOR_CODE},
        {title: 'Fish population: Deep sea', color: RED_COLOR_CODE},
        ]}
      />
      </div>
      <ScreenShotInstructionsPopup />
      </>
    );
  }

  render() {
    // Graphs are ordered for debriefing
    const panes = [
    { menuItem: 'Indices', pane: { key: 'tab1', content: (<Tab.Pane>{this.renderIndexGraph()}</Tab.Pane>)} },
    { menuItem: 'Total number of ships', pane: { key: 'tab2', content: (<Tab.Pane>{this.renderTotalShipsGraph()}</Tab.Pane>) } },
    { menuItem: 'Catch', pane: { key: 'tab3', content: (<Tab.Pane>{this.renderCatchGraph()}</Tab.Pane>) } },
    { menuItem: 'Fish stock', pane: { key: 'tab4', content: (<Tab.Pane>{this.renderFishStockGraph()}</Tab.Pane>) } },
  ]

    return (
      <>
        <Tab
          panes={panes}
          renderActiveOnly={false}
          activeIndex={this.state.activeIndex}
          onTabChange={this.handleTabChange}
        />
      </>
    );
  }









}

export default Graphs;
