import React from 'react';
import { connect } from 'react-redux';
import { Form, Button, Message, Icon } from 'semantic-ui-react';

import { teamDisplayName } from '../lib/util';
import IntegerInput from './IntegerInput';
import { setDecisions } from '../actions';
import { PRIMARY_COLOR } from '../stylingConstants';
import {sendTeamDecisionsToServer} from '../lib/updateGameStateOnServer';


const ALL_FIELD_DESCRIPTIONS = {
  "d1": "D1 Ships Purchased in Auction",
  "d2": "D2 Money Spent on Auction",
  "d3": "D3 Ships Purchased",
  "d4": "D4 Money Spent on Purchases",
  "d5": "D5 Ships Sold",
  "d6": "D6 Money Received from Sales",
  "d7": "D7 New Ships Ordered",
  "d8": "D8 Ship Fleet",
  "d9": "D9 Ships to Deep Sea",
  "d10": "D10 Ships to Coast",
  "d11": "D11 Ships in Harbor",
}



class EnterDecisions extends React.Component {

  constructor(props) {

  super(props);
    var teams = [
      {name: "A", number: 1, numberOfShips: 4},
      {name: "B", number: 2, numberOfShips: 6},
      {name: "C", number: 3, numberOfShips: 8}];
    if(this.props.teams) {
      teams = this.props.teams;
    }
    const teamDecisions = teams.map((team) => {
      var initialValues = {};
      for(var key of Object.keys(ALL_FIELD_DESCRIPTIONS)) {
        initialValues[key] = 0;
      }
      return initialValues;
    });
    const errors = teams.map((team) => {
      var initialErrors = {};
      for(var key of Object.keys(ALL_FIELD_DESCRIPTIONS)) {
        initialErrors[key] = false;
      }
      return initialErrors;
    });
    this.setCalculatedValues(teamDecisions,teams);
    this.state = {
      teams,
      teamDecisions,
      errors,
      decisionsSent: false,
    }
  }

  componentDidUpdate(prevProps) {
    // Need to compare props to not cause infinite loop
    // If we have got a new remote decision we need to update
    if (this.props.remoteDecisions.length !== prevProps.remoteDecisions.length) {
      this.updateStateWithRemoteChanges(this.props.remoteDecisions[this.props.remoteDecisions.length - 1])
    }
  }

  updateStateWithRemoteChanges({teamId, decisions}) {
    const indexOfTeamId = this.state.teams.findIndex((team) => team.id === teamId);
    const newDecisions = [...this.state.teamDecisions];
    newDecisions[indexOfTeamId] = decisions;
    // This call should not be necessary but adding it here just to make sure the calculated values are ok
    this.setCalculatedValues(newDecisions, this.state.teams);
    this.setState({teamDecisions: newDecisions})
  }

  // Note: This function changes the teamDecisions variable
  setCalculatedValues(teamDecisions, teams) {
    for(var teamIndex = 0; teamIndex < teams.length; teamIndex++) {
      teamDecisions[teamIndex]['d8'] = teams[teamIndex].numberOfShips
        + parseInt(teamDecisions[teamIndex]['d1'])
        + parseInt(teamDecisions[teamIndex]['d3'])
        - parseInt(teamDecisions[teamIndex]['d5']);
      teamDecisions[teamIndex]['d11'] = teamDecisions[teamIndex]['d8']
        - parseInt(teamDecisions[teamIndex]['d9'])
        - parseInt(teamDecisions[teamIndex]['d10']);
    }
  }

  // This function returns the actual callback that is sent to the field
  getChangedTableInputCallback(teamIndex, fieldName) {
    return (event, isError) => {
      var teamDecisions = this.state.teamDecisions;
      var errors = this.state.errors;
      teamDecisions[teamIndex][fieldName] = event.target.value;
      errors[teamIndex][fieldName] = isError;
      this.setCalculatedValues(teamDecisions, this.state.teams);
      this.setState({teamDecisions,errors});
    };
  }

  sumForField(fieldName){
    var sum = 0;
    for(var team of this.state.teamDecisions) {
      sum += parseInt(team[fieldName]);
    }
    return sum;
  }

  renderTableHeader() {
    return (
      <thead>
      <tr>
        <th>&nbsp;</th>
        {this.state.teams.map((team, index) => <th key={`decisions_header_${index}`}>{teamDisplayName(team)}</th>)}
        {this.state.teams.length !== 1 && <th>Total</th>}
      </tr>
      </thead>
    );
  }

  renderTableRowForField(fieldName, rowIndex, allowNegative = false) {
    return (
      <tr>
        <td>{ALL_FIELD_DESCRIPTIONS[fieldName]}</td>
        {this.state.teams.map((team, teamIndex) => (
          <td key={`decisions_table_${rowIndex}_${teamIndex}`}>
            <IntegerInput
              allowNegative={allowNegative}
              tabIndex={1+teamIndex*9 + rowIndex}
              onChange={this.getChangedTableInputCallback(teamIndex, fieldName).bind(this)}
              value={this.state.teamDecisions[teamIndex][fieldName]}
            />
          </td>)
        )}
        { this.state.teams.length !== 1 && <td>{this.sumForField(fieldName)}</td> }
      </tr>
    )
  }

  renderTableRowForNonEditableField(fieldName) {
    return (
      <tr>
        <td>{ALL_FIELD_DESCRIPTIONS[fieldName]}</td>
        {this.state.teams.map((team, teamIndex) => (
          <td key={`decisions_no_edit_${teamIndex}`}>
            {this.state.teamDecisions[teamIndex][fieldName]}
          </td>)
        )}
        { this.state.teams.length !== 1 && <td>{this.sumForField(fieldName)}</td>}
      </tr>
    )
  }

  isFormErrorFree() {
    return this.allFieldsValid()
      && !this.hasTotalShipError()
      && !this.renderTotalShipPaymentsError()
      && !this.renderToManyShipsOrderedError()
      && !this.renderShipFleetsMustNotBeNegativeError()
      && !this.renderShipsInHarborMustNotBeNegativeError()
  }

  allFieldsValid() {
    for (var teamFieldErrors of this.state.errors) {
      for(var key of Object.keys(ALL_FIELD_DESCRIPTIONS)) {
        if(teamFieldErrors[key]) {
          return false;
        }
      }
    }
    return true;
  }

  hasTotalShipError() {
    if(this.props.singleTeamMode) {
      // Single team mode does not have this validation criteria
      return false
    }
    return this.sumForField('d3') !== this.sumForField('d5');
  }

  renderTotalShipError() {
    if(this.hasTotalShipError()) {
      return (
        <Message
          error
          content="Total of D3, Ship Purchases, must equal the Total of D5, Ships Sold"
        />
      )
    } else {
      return;
    }
  }

  hasTotalShipPaymentsError() {
    if(this.props.singleTeamMode) {
      // Single team mode does not have this validation criteria
      return false
    }
    return this.sumForField('d4') !== this.sumForField('d6')
  }

  renderTotalShipPaymentsError() {
    if(this.hasTotalShipPaymentsError()) {
      return (
        <Message
          error
          content="Total of D4, Ship Purchases $, must equal the Total of D6, Ship Sales $"
        />
      )
    } else {
      return;
    }
  }

  hasToManyShipsOrderedError() {
    for (var teamDecision of this.state.teamDecisions) {
      if(parseInt(teamDecision['d7']) > Math.round(teamDecision['d8']/2)) {
        return true;
      }
    }
    return false;
  }

  renderToManyShipsOrderedError() {
    if(this.hasToManyShipsOrderedError()) {
      return (
        <Message
          error
          content="No team may order in one year more ships than half their existing fleet."
        />
      )
    } else {
      return;
    }
  }

  hasShipFleetsMustNotBeNegativeError() {
    for (var teamDecision of this.state.teamDecisions) {
      if(parseInt(teamDecision['d8']) < 0) {
        return true;
      }
    }
    return false;
  }

  renderShipFleetsMustNotBeNegativeError() {
    if(this.hasShipFleetsMustNotBeNegativeError()) {
      return (
        <Message
          error
          content="Ship fleet must not be negative for any team."
        />
      )
    } else {
      return;
    }
  }

  hasShipsInHarborMustNotBeNegativeError() {
    for (var teamDecision of this.state.teamDecisions) {
      if(parseInt(teamDecision['d11']) < 0) {
        return true;
      }
    }
    return false;
  }

  renderShipsInHarborMustNotBeNegativeError() {
    if(this.hasShipsInHarborMustNotBeNegativeError()) {
      return (
        <Message
          error
          content="The sum of ships sent to the Deep Sea and to the Coast fisheries for each team must be less than or equal to the Ships Available"
        />
      )
    } else {
      return;
    }
  }

  renderAdditionalErrors() {
    return (
      <div>
        {this.renderTotalShipError()}
        {this.renderTotalShipPaymentsError()}
        {this.renderToManyShipsOrderedError()}
        {this.renderShipFleetsMustNotBeNegativeError()}
        {this.renderShipsInHarborMustNotBeNegativeError()}
      </div>
    )
  }

  onFormCompletedPressed() {
    if (this.props.singleTeamMode) {
      this.singleTeamFormCompleted();
      return;
    }
    var finalDecisions = [];
    for( var teamIndex = 0; teamIndex < this.state.teams.length; teamIndex++) {
      const teamDecision = this.state.teamDecisions[teamIndex];
      finalDecisions[teamIndex] = {
        auctionShips: parseInt(teamDecision['d1']),
        auctionDols: parseInt(teamDecision['d2']),
        shipPurch: parseInt(teamDecision['d3']),
        shipPurchDols: parseInt(teamDecision['d4']),
        shipSales: parseInt(teamDecision['d5']),
        shipSalesDols: parseInt(teamDecision['d6']),
        shipOrders: parseInt(teamDecision['d7']),
        shipsToDeep: parseInt(teamDecision['d9']),
        shipsToCoast: parseInt(teamDecision['d10'])
      };
    }
    this.props.setDecisions(finalDecisions);
    this.props.callbackAfterDecisions();
    window.scrollTo(0,0);
  }

  singleTeamFormCompleted() {
    console.log('Single team form completed, send to backend');
    sendTeamDecisionsToServer(this.state.teams[0].id, this.state.teamDecisions[0]);
    this.setState({decisionsSent: true});
    window.scrollTo( 0, 0);
  }

  renderSubmit() {
    return (
      <Button
        fluid
        icon
        onClick={this.onFormCompletedPressed.bind(this)}
        disabled={!this.isFormErrorFree() || this.state.decisionsSent}
        color={PRIMARY_COLOR}
        size='big'
        className='spaced'
      >
        <Icon name='ship' />
        &nbsp;Go Fishing
      </Button>
    );
  }

  render() {
    return (
      <div>
        <h3>Decisions</h3>
        {this.state.decisionsSent && (
          <Message success>
          <Message.Header>Your decisions have been sent</Message.Header>
          <p>
            Waiting for host to collect and validate decisions from all teams.
          </p>
        </Message>
        )}
        <Form>
          <table className="ui definition table unstackable collapsing">
            {this.renderTableHeader()}
            <tbody>
            {this.renderTableRowForField('d1', 0, true)}
            {this.renderTableRowForField('d2', 1, true)}
            {this.renderTableRowForField('d3', 2)}
            {this.renderTableRowForField('d4', 3)}
            {this.renderTableRowForField('d5', 4)}
            {this.renderTableRowForField('d6', 5)}
            {this.renderTableRowForField('d7', 6)}
            <tr><td colSpan={this.state.teams.length + 2}>&nbsp;</td></tr>
            {this.renderTableRowForNonEditableField('d8')}
            {this.renderTableRowForField('d9', 7)}
            {this.renderTableRowForField('d10', 8)}
            {this.renderTableRowForNonEditableField('d11')}
            </tbody>
          </table>
        </Form>
        {this.renderAdditionalErrors()}
        {this.renderSubmit()}
      </div>
    );
  }

}

const mapStateToProps = (state) => {
  const gameState = state.gameStates[state.gameStates.length-1];
  const allTeams = gameState && gameState.teams;
  let teams = [];
  const teamId = state.onlineState.teamId;
  console.log(allTeams, teamId);
  if(teamId) {
    const team = allTeams.find((team) => team.id === teamId);
    teams = [team];
  } else {
    teams = allTeams;
  }
  const singleTeamMode = !!state.onlineState.teamId;
  return {
    singleTeamMode,
    teams,
    year: state.gameStates.length,
    remoteDecisions: state.remoteDecisions,
  };
}

export default connect(mapStateToProps, {
  setDecisions
})(EnterDecisions);
