import React from 'react';
import { connect } from 'react-redux';
import { Form, Button } from 'semantic-ui-react';

import { setLicenseKey } from '../actions';
import { validateKey } from '../lib/licenseValidator';
import { PRIMARY_COLOR } from '../stylingConstants';

class LicenseKeyForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      licenseKey: "",
      errorMessage: "",
    };
  }

  onChange(event) {
    this.setState({
      licenseKey: event.target.value,
      errorMessage: '',
    });
  }

  onValidateClicked() {
    if(validateKey(this.state.licenseKey)) {
      this.props.setLicenseKey(this.state.licenseKey);
    } else {
      this.setState({
        errorMessage: 'Invalid key, please double check your input',
      })
    }
  }

  render() {
    return (
      <>
        <p>
          Please enter your license key below.
        </p>
        <Form>
        <Form.Input
          required
          type="text"
          label="License Key"
          value={this.state.licenseKey}
          onChange={this.onChange.bind(this)}
          {...(this.state.errorMessage ?
            {error: {content: this.state.errorMessage }} : {})}
        />
        <Button color={PRIMARY_COLOR} onClick={this.onValidateClicked.bind(this)}>Validate Key</Button>
        </Form>
      </>
    );
  }

}

export default connect(null, { setLicenseKey })(LicenseKeyForm);
