import React from 'react';
import TeamReport from './TeamReport'

class TeamReports extends React.Component {

  render() {
    const gameState = this.props.gameState;
    const {teams} = gameState;
    return (
      <div style={{marginTop: '20px'}}>
      <h3>Team Reports</h3>
      <hr />
      {teams.map((team, teamIndex) => {
        const {deepSeaCatch, coastCatch, totalFishSales, interest} = team.teamReportData;
        return (
            <TeamReport key={`team_${teamIndex}`} team={team} year={gameState.year} fishSalesPrice={gameState.fishSalesPrice} />
            );
      })}
      </div>
    );
  }

}

export default TeamReports;
