import React from 'react';
import {teamDisplayName} from "../lib/util";

export default function GameBoard({gameState}) {

  const {totalShipOrders} = gameState.operatorReportData;

  console.log('In Gameboard', gameState);
  let totalShipsToDeep = 0;
  let totalShipsToCoast = 0;
  let totalShipsToHarbor = 0;

  const teamRows = gameState.teams.map((team, index) => {
    const {shipsToDeep, shipsToCoast} = gameState.decisions[index];
    const numberOfShips = team.numberOfShips;
    const shipsToHarbor = numberOfShips - shipsToDeep - shipsToCoast;

    totalShipsToDeep += shipsToDeep;
    totalShipsToCoast += shipsToCoast;
    totalShipsToHarbor += shipsToHarbor;

    return (
      <tr key={index}>
        <td>{teamDisplayName(team)}</td>
        <td>{shipsToDeep}</td>
        <td>{shipsToCoast}</td>
        <td>{shipsToHarbor}</td>
      </tr>
    );
  });

  //const totalShips = totalShipsToDeep + totalShipsToCoast + totalShipsToHarbor;

  const sumRow = (
    <tr key="sum" className='summed-row'>
      <td>Sum</td>
      <td>{totalShipsToDeep}</td>
      <td>{totalShipsToCoast}</td>
      <td>{totalShipsToHarbor}</td>
    </tr>
  );

  return (
    <>
      <h3>Team boat placement</h3>
      <p>This is how the teams have chosen to use their fleet.</p>
      <table className="ui definition celled selectable table unstackable striped">
        <thead>
        <tr>
          <th>Team</th>
          <th>Deep sea</th>
          <th>Coast</th>
          <th>Harbor</th>
        </tr>
        </thead>
        <tbody>
          {teamRows}
          {sumRow}
        </tbody>
      </table>
      <p style={{fontWeight: 'bold'}}>There are {totalShipOrders} ships in the shipyard.</p>
    </>
  );
}