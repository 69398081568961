import {createConsumer} from "@rails/actioncable";

export default class SocketHandler {

  static consumer = null;
  static subsciption = null;

  static subscribeToTeamChannel(teamId, callbacks) {
    SocketHandler.prepareForNewSubscription();
    SocketHandler.subscription = SocketHandler.consumer.subscriptions.create({ channel: "TeamChannel", team_id: teamId }, {
      received(data) {
        console.log('SocketHandler: New message in TeamChannel', teamId, data)
        callbacks.received(data);
      }});
    console.log('Subscribing to team channel:', teamId);
  }

  static subscribeToHostChannel(gameId, callbacks) {
    SocketHandler.prepareForNewSubscription();
    SocketHandler.subscription = SocketHandler.consumer.subscriptions.create({ channel: "HostChannel", game_id: gameId }, {
      received(data) {
        console.log('SocketHandler: New message in HostChannel', gameId, data)
        callbacks.received(data);
      }});
    console.log('Subscribing to HostChannel', gameId);
  }

  static disconnectFromWebSocket() {

  }

  static prepareForNewSubscription() {
    if( !SocketHandler.consumer ) {
      SocketHandler.consumer = createConsumer('/cable');
    }
  }
}