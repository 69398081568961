export function validateKey(key) {
	if( !key ) {
		return false;
	}
	var i;
	var testsum;
	var teststr;
	var testArr = [3,5,4,7];
	var testexp = /^(\w\w[BRQT]\w)-(\w[AJL]\w\w)-(\w\w\w[3679])-([DGKPX]\w\w\w)$/;
	key = key.toUpperCase();
	var result = key.match(testexp);

	if (result != null) {
		testsum = 0;
		teststr = result[1];
		for (i = 0; i < teststr.length; i++) {
			testsum += teststr.charCodeAt(i);
		}
		if (testsum % testArr[0] === 0) {
			testsum = 0;
			teststr = result[2];
			for (i = 0; i < teststr.length; i++) {
				testsum += teststr.charCodeAt(i);
			}
			if (testsum % testArr[1] === 0) {
				testsum = 0;
				teststr = result[3];
				for (i = 0; i < teststr.length; i++) {
					testsum += teststr.charCodeAt(i);
				}
				if (testsum % testArr[2] === 0) {
					testsum = 0;
					teststr = result[4];
					for (i = 0; i < teststr.length; i++) {
						testsum += teststr.charCodeAt(i);
					}
					if (testsum % testArr[3] === 0) {
						return true;
					}
				}
			}
		}
	}
	return false;
}
