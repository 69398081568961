export function updateGameStateOnServer(gameId, gameState) {
  if(!gameId) {
    return;
  }
  console.log('Updating game state on server', gameId, gameState);
  const url = `/games/${gameId}/game_state_update_from_host`;
  fetch(url, {
    "method": "POST",
    "headers": {
      "content-type": "application/json",
      "accept": "application/json"
    },
    "body": JSON.stringify({'game_state': gameState})
  })
    .then(response => response.json())
    .then(t => {
      console.log(t)
      }
    )
    .catch(err => {
      console.log(err);
    });
}

export function sendTeamDecisionsToServer(teamId, teamDecisions) {
  console.log('Sending team decisions to server', teamId, teamDecisions);
  const url = `/teams/${teamId}/set_decisions`;
  fetch(url, {
    "method": "POST",
    "headers": {
      "content-type": "application/json",
      "accept": "application/json"
    },
    "body": JSON.stringify({'decisions': teamDecisions})
  })
    .then(response => response.json())
    .then(t => {
        console.log(t)
      }
    )
    .catch(err => {
      console.log(err);
    });
}