import React from 'react';
import { Form } from 'semantic-ui-react';

class IntegerInput extends React.Component {

  // Props must be
  // value
  // allowNegative
  // onchange
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: null,
    }
  }

  onChange(event) {
    const value = event.target.value;
    var parsedValue = parseInt(value)
    var errorMessage = null;
    if (isNaN(parsedValue) || event.target.value === "") {
      errorMessage = 'You must use a numeric value in this field';
    } else if (!this.props.allowNegative && value < 0) {
      errorMessage = 'You must use a non-negative\nvalue in this field';
    } else if (parseFloat(value) !== parseInt(value)) {
      errorMessage = 'You must use an integer value in this field'
    }
    const error = errorMessage !== null;
    this.props.onChange(event, error);
    this.setState({ errorMessage });
  }

  minimumValue() {
    if(this.props.allowNegative) {
      return -10000000000000;
    } else {
      return 0;
    }
  }

  renderErrorMessage() {
    if(this.state.errorMessage === null) {
      return <div></div>;
    } else {
      return <span>{this.state.errorMessage}</span>
    }
  }

  render() {
    // We render the input as text instead of number, since number inputs
    // change when the input is selected and you use the scroll wheel on
    // the mouse (at least on safari)
    return (
      <div>
      <Form.Input
        required
        type="text"
        tabIndex={this.props.tabIndex}
        value={this.props.value}
        onChange={this.onChange.bind(this)}
        {...(this.state.errorMessage ?
          {error: {content: this.state.errorMessage }} : {})}
      />
      </div>
    );
  }

}

export default IntegerInput;
