import React from 'react';

import {renderDollarAmount, roundToDecimal, teamDisplayName} from '../lib/util';
import {weatherAsString} from "../lib/weather";

class TeamResultsPerYear extends React.Component {

  weatherText(weather) {
    if (this.props.showWeatherDetails) {
      return weatherAsString(weather) + " (" + roundToDecimal(weather,2) + ")";
    } else {
      return weatherAsString(weather);
    }
  }

  renderGameStateRow(gameState) {
    return (
      <tr key={`gamestate_${gameState.year}`}>
        <td>{gameState.year}</td>
        <td>{this.weatherText(gameState.operatorReportData.weather)}</td>
        {gameState.teams.map((team, index) => <td key={`team_${index}`}>{team.numberOfShips} <span role="img" aria-label="ship">🚢</span><br/>{renderDollarAmount(team.bankBalance + team.numberOfShips * gameState.shipSalvageValue)}</td>)}
      </tr>
    );
  }

  render() {
    return (
      <div>
      <p>The table shows the number of boats each team has and their total assets (Bank balance + Number of Ships x Salvage Value)</p>
      <table className="ui definition celled selectable table unstackable striped">
        <thead>
          <tr>
            <th>Year</th>
            <th>Weather</th>
            {this.props.gameStates[0].teams.map((team, index) => <th key={`team_${index}`}>{teamDisplayName(team)}</th>)}
          </tr>
        </thead>
        <tbody>
          {this.props.gameStates.map((gameState) => this.renderGameStateRow(gameState))}
        </tbody>
        </table>
        </div>
      );
  }

}

export default TeamResultsPerYear;
