// Returns a random number between 0.8 and 1.2, not normal distributed, but larger probabilities for numbers
// close to 1.0
const MINIMUM_WEATHER = 0.8;
const MAXIMUM_WEATHER = 1.2;

export function randomWeather(){
  const averageOfThreeRandomNumbers = (Math.random() + Math.random() + Math.random())/3.0;
  return MINIMUM_WEATHER + averageOfThreeRandomNumbers * (MAXIMUM_WEATHER - MINIMUM_WEATHER);
}

export function weatherAsString(weather) {
  if(weather < 0.9) {
    return "Very poor";
  } else if (weather < 0.98) {
    return "Poor";
  } else if (weather <= 1.02) {
    return "Indifferent";
  } else if (weather <= 1.1) {
    return "Good";
  }
  return "Very good";
}
