// These must be the first lines in src/index.js
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
// End imports from react-app-polyfill

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/es/integration/react';

import 'semantic-ui-css/semantic.min.css';
import 'src/index.css';
import App from 'src/App';
import configureStore from 'src/configureStore';
import * as serviceWorker from 'src/serviceWorker';


const { persistor, store } = configureStore();

// This should give a warning popup if user tries to leave the page, so that they have to confirm that is what they want
window.addEventListener('beforeunload', function (e) {
    // Cancel the event
    e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
    // Chrome requires returnValue to be set
    e.returnValue = '';
});

document.addEventListener('DOMContentLoaded', () => {
    ReactDOM.render(
        <Provider store={store}>
            <PersistGate persistor={persistor}>
                <App/>
            </PersistGate>
        </Provider>,
        document.getElementById('root')
    );

    function onUpdate() {
        store.dispatch({type: 'SERVICE_WORKER_UPDATE_AVAILABLE'});
    }

    function onSuccess() {
        store.dispatch({type: 'SERVICE_WORKER_SUCCESSFULLY_INSTALLED'});
    }

    //serviceWorker.register({onUpdate, onSuccess});

});