import React from 'react';
import SetupForm from './components/SetupForm';
import GatherOnlineTeams from './components/GatherOnlineTeams';
import ShowFishData from './components/ShowFishData';
import Landing from './components/Landing';
import Header from './components/Header';
import { MemoryRouter, Route } from 'react-router-dom';
import { Container } from 'semantic-ui-react';


function App() {
  return (
    <>
      <MemoryRouter>
        <Container>
          <Header />
          <Route path="/" exact component={Landing} />
          <Route path="/new" component={SetupForm} />
          <Route path="/new_online" component={GatherOnlineTeams} />
          <Route path="/show/" component={ShowFishData} />
        </Container>
      </MemoryRouter>
    </>
  );
}

export default App;
