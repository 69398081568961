import React from 'react';
import { Modal, Header, Button, Icon, Form, Menu } from 'semantic-ui-react';

import { MAX_TEAM_NAME_LENGTH } from '../constants';

class TeamsEditModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      teamNames: [...props.initialTeamNames],
      nameErrors: new Array(props.initialTeamNames.length)
    };
  }


  handleOpen = () => {
    this.setState({ modalOpen: true });
  }

  handleDiscard = () => this.setState({
    modalOpen: false,
    teamNames: [...this.props.initialTeamNames]
  });

  handleSubmit = () => {
    this.props.setTeamNames(this.state.teamNames);
    this.setState({ modalOpen: false });
  }

  renderFormInputs() {
    return this.state.teamNames.map((teamName,index) => <Form.Input
      key={`teamedit_${index}`}
      label={`Name of Team ${index + 1}`}
      type="text"
      value={teamName}
      onChange={(event, {value}) => {
        let teamNames = this.state.teamNames;
        teamNames[index] = value;
        let nameErrors = this.state.nameErrors;
        nameErrors[index] = null;
        if(value.length < 1) {
          nameErrors[index] = "You must enter a name";
        }
        if(value.length > MAX_TEAM_NAME_LENGTH) {
          nameErrors[index] = `Maximum length of name is ${MAX_TEAM_NAME_LENGTH} characters`;
        }
        this.setState({teamNames, nameErrors});

      }}
      { ...(this.state.nameErrors[index] ?
        {error: {content: this.state.nameErrors[index] }} : {})}
      />);
  }


  render() {
    return (
      <Modal
        trigger={
        <Menu.Item
          onClick={this.handleOpen}
        >
          Edit team names
        </Menu.Item>
      }
        open={this.state.modalOpen}
        basic
        size='small'
      >
        <Header icon='browser' content='Edit team names' />
        <Modal.Content>
          <Form inverted>
            {this.renderFormInputs()}
          </Form>

        </Modal.Content>
        <Modal.Actions>
          <Button color='grey' onClick={this.handleDiscard} inverted>
            <Icon name='times' />Discard
          </Button>
          <Button
            color='green'
            onClick={this.handleSubmit}
            disabled={this.state.nameErrors.reduce((result, value) => result || value, false)}
            inverted>
            <Icon name='checkmark' />Save
          </Button>

        </Modal.Actions>
      </Modal>
    );
  }

}

export default TeamsEditModal;
