import React from 'react';

export function roundToDecimal(number, numberOfDecimals) {
  return Math.round(number*Math.pow(10,numberOfDecimals))/Math.pow(10,numberOfDecimals);
}

export function renderDollarAmount(amount) {
  if( amount >= 0 ) {
    return(
      <span className='positive'>{'$' + amount}</span>
    );
  } else {
    return (
      <span className='negative'>{'-$' + Math.abs(amount)}</span>
    );
  }
}

// The team name to display
export function teamDisplayName(team) {
  if (team.name === `Team ${team.number}`) {
    return team.name;
  }
  return `${team.name} (Team ${team.number})`;
}
