import React from "react";
import { Redirect } from 'react-router-dom';
import {Button, Icon, Message} from 'semantic-ui-react';
import {connect} from "react-redux";
import {receivedFromHostChannel, startedHosting, quitCurrentGame} from "../actions";
import SocketHandler from "../lib/SocketHandler";
import {PRIMARY_COLOR} from "../stylingConstants";

class GatherOnlineTeams extends React.Component {

  state = {
    joinCode: "",
    redirectToSetupForm: false,
    redirectToHome: false,
  }

  componentDidMount() {
    const outerThis = this;
    fetch("/games/", {
      "method": "POST",
      "headers": {
        "content-type": "application/json",
        "accept": "application/json"
      },
      "body": JSON.stringify({})
    })
      .then(response => response.json())
      .then(game => {
        console.log(game);
        if(game) {
          this.props.startedHosting(game);
          SocketHandler.subscribeToHostChannel(game.id, {
            received(data) {
              console.log(data);
              outerThis.props.receivedFromHostChannel(data);
          }});
        }
        this.setState({joinCode: game.code})
      })
      .catch(err => {
        console.log(err);
      });
  }

  startGameClicked() {
    const url = `/games/${this.props.gameId}/close_for_new_joins`
    fetch(url, {
      "method": "POST",
      "headers": {
        "content-type": "application/json",
        "accept": "application/json"
      },
      "body": JSON.stringify({})
    });
    this.setState({redirectToSetupForm: true});
  }

  backToStartPage(event) {
    event.preventDefault();
    this.props.quitCurrentGame();
    this.setState({redirectToHome: true});
  }

  renderTeams(teams) {
    if( teams.length === 0) {
      return (
        <p>
          Waiting for teams to register, you need at least one team...
        </p>)
        ;
    }
    return (
      <ul>
        {teams.map((team) => <li key={team.id}>{team.name}</li>)}
      </ul>
    );
  }

  renderJoinInfo(joinCode) {
    return (
      <div>
      <p>Now give the teams the following instructions on how to join the game:</p>
      <Message className="huge violet">
        <Message.Header>
          To Join
        </Message.Header>
        Navigate to
        <span style={{fontWeight: 'bold'}}>{" " + window.location.hostname + " "}</span>
        and enter the code:
        <span style={{fontWeight: 'bold'}}>{" " + joinCode}</span>
      </Message>
      <p>&nbsp;</p>
      </div>
    )
  }

  render() {
    if (this.props.redirectToHome || this.state.redirectToHome) {
      return (
        <Redirect to='/' />
      );
    }
    if (this.state.redirectToSetupForm) {
      return (
        <Redirect to='/new' />
      )
    }
    return (
      <>
        <Button onClick={this.backToStartPage.bind(this)}>
          <Icon name="angle left" />Back to start page
        </Button>
        {this.state.joinCode.length > 0 ? <h2>New game created</h2> : null}
        <p>
          Maximum number of teams is 6. Make sure that each team consists of at least two players, in order to
          encourage discussions within the teams. Usually it is better to have more teams than fewer.
        </p>
        {this.state.joinCode.length > 0 ? this.renderJoinInfo(this.state.joinCode) : <p>Creating game...</p>}
        <h3>Registered teams</h3>
        <p>When you see all the teams you want in the game below, click the 'Start game' button.</p>
        {this.state.joinCode.length > 0 && this.renderTeams(this.props.teams)}
        <Button
          disabled={this.props.teams.length === 0}
          onClick={this.startGameClicked.bind(this)}
          color={PRIMARY_COLOR}
        >Start game</Button>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    teams: state.joinedTeams,
    redirectToHome: !state.licenseKey,
    gameId: state.onlineState.gameId,
  };
}

export default connect(mapStateToProps, {
  receivedFromHostChannel,
  startedHosting,
  quitCurrentGame,
})(GatherOnlineTeams);