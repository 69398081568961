export function initialNumberOfShipsFromNumberOfTeams(numberOfTeams) {
  if( numberOfTeams === 5 ) {
    return 5;
  }
  else {
    return 24/numberOfTeams;
  }
}

export function initialBankBalanceFromNumberOfTeams(numberOfTeams) {
  return initialNumberOfShipsFromNumberOfTeams(numberOfTeams) * 200;
}
